<template>
  <div class="materialsList fadeInRight app-container">
    <div class="title-box clearfix">
      <el-row :gutter="40">
        <el-col :span="19" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="queryParams"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
              <el-form-item label="" :label-width="formLabelWidth" prop="imei">
                <el-input
                  placeholder="请输入设备IMEI"
                  v-model="queryParams.imei"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="searchData"
                  class="themed-button"
                  >搜索</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
        <el-col :span="5" :xs="24"
          ><div class="grid-content bg-purple">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              style="height: auto; line-height: 40px; margin-top: 5px"
            >
            <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="importAdd"
                  >导入</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="minorAdditions"
                  >新增分配</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  icon="el-icon-plus"
                  type="primary"
                  class="themed-button"
                  @click="delectAll"
                  >批量删除</el-button
                >
              </el-form-item>
            </el-form>
          </div></el-col
        >
      </el-row>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :row-style="{ height: '45px' }"
        :cell-style="{ padding: '0px' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection"   width="50" />
        <el-table-column prop="id" label="主键ID" min-width="180">
        </el-table-column>
        <el-table-column
          prop="imei"
          label="IMEI"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          prop="qrcodeId"
          label="配置ID"
          min-width="180"
        >
        </el-table-column>
        <el-table-column
          prop="sendTime"
          label="发送时间"
          min-width="180"
          :formatter="sendTimeFilter"
        >
         <template slot-scope="scope">
          <span>{{ scope.row.sendTime == ""||scope.row.sendTime == null ? "" :scope.row.sendTime}}</span>
         </template>
        </el-table-column>
        <el-table-column
          prop="state"
          label="状态"
          min-width="180"
        >
         <template slot-scope="scope">
                <span>{{ scope.row.state === "0" ? "保存" :scope.row.state === "1" ? "成功":"失败"}}</span
                >
              </template>
        </el-table-column>
        <el-table-column label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="themed-button"
              @click="delect(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[5, 10, 15, 50, 100, 1000]"
        :page-size="pageSize"
        style="text-align: left; margin-top: 20px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
      <el-dialog title="批量导入" :visible.sync="dialogVisible" width="600px">
        <el-form
          ref="fileForm"
          :model="fileForm"
          label-width="120px"
          :rules="rules"
        >
          <el-form-item>
            <div style="height: 50px; line-height: 50px">
              下载模板:
              <a
                href="javascript:void(0);"
                @click="exportTemplateExcel('关联设备.xls')"
                style="color: blue"
                >关联设备</a
              >
            </div>
            <div style="height: 50px; line-height: 50px">
              <input class="file-input" type="file" ref="file" />
              <div class="text-color" style="font-size: 12px">
                仅支持.xls/.xlsx格式
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="importAureAdd('fileForm')"
            >确 定</el-button
          >
        </div>
      </el-dialog>
       <el-dialog title="新增分配" :visible.sync="dialogFormVisibleOne" width="40%">
        <el-form
          ref="addFormOne"
          :model="addFormOne"
          label-width="100px"
          :rules="rules"
        >
                  <el-form-item prop="imeiArray" label="设备IMEI">
                <el-input
                  class="choose-input"
                  type="textarea"
                  placeholder="请输入设备IMEI"
                  v-model="addFormOne.imeiArray"
                  :autosize="{ minRows: 3, maxRows: 4}"
                >
              </el-input>
              
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisibleOne = false"  type="info" class="themed-button">取 消</el-button>
          <el-button type="primary" @click="sureAddOnce('addFormOne')" class="themed-button"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { agentGetAllAgent } from "@/api/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Utils from "@/utils/utils.js";
import {
  detailPageList,
  importTemplate,
  detailImport,
  deleteDeleteOne,
  deleteDelete,
  detailAdd
} from "@/api/qrcode/qrcode.js";
export default {
  name: "materialsList",
  components: { Treeselect },
  //mixins: [resize],
  data() {
    return {
      formInline: {
        spec: "",
        material: "",
      },
      addForm: {
        imei: "",
      },
       addFormOne:{
       qrcodeId :"",
       imeiArray:""
      },
      fileForm: {},
      multipleSelection:[],
      tableData: [],
      materialList: [],
      specList: [],
      agentOptions: [],
      dialogFormVisible: false,
      dialogFormVisibleOne:false,
      dialogVisible: false,
      queryParams: {
        imei: "",
      },
      rules: {
        iemi: [
          {
            required: true,
            message: "设备IMEI不能为空",
            trigger: "blur",
          },
        ],
      },
      page: 1,
      pageSize: 15,
      total: 0,
      formLabelWidth: "10px",
      queryCount: "",
    };
  },
  created() {
    this.queryCount = JSON.parse(this.$route.query.data);
    this.getDeviceList();
  },
  methods: {
     sendTimeFilter (row, column) {
    // 转换后台返回时间格式
      const jsonDate = new Date(row.sendTime).toJSON()
      return new Date(new Date(jsonDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
    },
    /** 列表渲染 */
    getDeviceList() {
      this.loading = true;
      let params = {
        pageNum: this.page,
        pageSize: this.pageSize,
        qrcodeId:this.queryCount.id
      };
      Object.assign(this.queryParams, params);
      detailPageList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
        this.loading = false;
      });
    },
    /** 重置表单 */
    reset() {
      this.addForm = {
        imei: "",
      };
      //this.resetForm("addForm");
    },
    /** 新增按钮弹出dialog */
    minorAdditions() {
      this.reset();
      this.dialogFormVisibleOne = true;
    },
    /** 单个新增提交 */
     sureAddOnce() {
      let flag = true;
      this.$refs["addFormOne"].validate((valid) => {
        if (valid) {
          let queryFox = new FormData();
          queryFox.append("qrcodeId", this.queryCount.id);
           let real_src_list = this.addFormOne.imeiArray.split("\n");
            let list = Array.from(real_src_list);
             if (list.length > 10) {
                flag = false;
                this.$alert("输入上限为10组", "提示", {
                  confirmButtonText: "确定",
                });
              } else {
                list.forEach((item, i) => {
                   const numberConst=!isNaN(parseFloat(item))
                    if (!numberConst) {
                    flag = false;
                    this.$alert("只能为数字", "提示", {
                      confirmButtonText: "确定",
                    });
                  }
                  console.log(item.length)
                  if (item.length > 17||item.length <15) {
                    flag = false;
                    this.$alert("每组为15位到17位长度的数字", "提示", {
                      confirmButtonText: "确定",
                    });
                  } else {
                   queryFox.append("imeiArray", item);
                  }
                });
              }
               if (flag) {
              detailAdd(queryFox).then((res) => {
                if (res.code == "0") {
                  const alert_content = res.msg
                  this.$alert(alert_content, "提示", {
                    confirmButtonText: "确定",
                    dangerouslyUseHTMLString: true
                  }).then(() => {
                    this.dialogFormVisibleOne = false;
                    this.getDeviceList();
                  });
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                  });
                }
              });
            }
        }
      });
    },
    /** 单个删除 */
    delect(row) {
      this.$confirm("是否确认删除该项数据?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteDeleteOne({id:row.id}).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getDeviceList();
        });
      });
    },
    // 全选
     handleSelectionChange(val) {
     this.multipleSelection = val;
    },
    // 批量删除
     delectAll() {
      if(this.multipleSelection.length<=0){
        this.$modal.msgWarning("请选择要删除的数据");
        return
      }
      this.$modal.confirm("是否确认删除选中项数据？").then(() => {
        let ids = [];
        this.multipleSelection.map((item) => ids.push(item.id));
        deleteDelete({ ids: ids.join(",") }).then((res) => {
          this.$modal.msgSuccess("删除成功");
           this.getDeviceList();
        });
      });
    },

    /** 导入唤醒dailog按钮 */
    importAdd() {
      this.dialogVisible = true;
    },
    /** 导出模板 */
    exportTemplateExcel(name) {
      importTemplate({ name: name }).then((res) => {
        const filename = name;
        Utils.createDownload(res, filename);
      });
    },
    /** 批量新增确定提交按钮 */
    importAureAdd() {
      this.$confirm("确定导入吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let file = this.$refs["file"].files[0];
        if (file) {
          let fileName = file.name;
          let index = fileName.lastIndexOf(".");
          if (
            index < 0 ||
            !(
              fileName.substring(index + 1, fileName.length) == "xls" ||
              fileName.substring(index + 1, fileName.length) == "xlsx"
            )
          ) {
            this.$alert("文件格式错误", "提示", {
              confirmButtonText: "确定",
            });
            return;
          } else if (!!Utils.checkFile(file)) {
            if (Utils.checkFile(file) == 1) {
              messageBox(this, "上传文件不能超过20M");
              return;
            } else if (Utils.checkFile(file) == 2) {
              messageBox(this, "上传文件名称长度不能超过36个字符");
              return;
            }
          } else {
            let param = new FormData();
            param.append("file", file);
            param.append("qrcodeId", this.queryCount.id);
            detailImport(param).then((res) => {
              if (res.code == "0") {
                this.$alert("上传成功", "提示", {
                  confirmButtonText: "确定",
                }).then(() => {
                  this.dialogVisible = false;
                  this.getDeviceList();
                  //this.$router.push("/equipmentManagement/historyList");
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.system_result_message_key,
                  type: "error",
                });
              }
            });
          }
        } else {
          this.$alert("请选择文件", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      });
    },
    /** 搜索 */
    searchData() {
      this.page = 1;
      this.getDeviceList();
    },
    /** 分页 */
    onPageChange(page) {
      this.page = page;
      this.getDeviceList();
    },
     handleSizeChange(size) {
      this.pageSize = size;
      this.getDeviceList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vue-treeselect {
  width: 250px;
}
::v-deep .el-dialog__header {
  border-bottom: 2px solid rgb(236, 231, 231);
}
.choose-title {
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgb(236, 231, 231);
  color: rgb(255, 151, 2);
}
.choose-option {
  height: 100px;
  line-height: 100px;
}
.choose-optionOne {
  padding-top: 35px;
  height: 100px;
}

.choose-imei {
  display: flex;
  margin-top: 30px;
  .choose-imei-title {
    margin-right: 100px;
  }
}
.choose-input {
  width: 80%;
}
.m-title {
  color: #676a6c;
  font-weight: 700;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  padding-left: 50px;
  border-bottom: 1px solid #d1d4d6;
}
.materialsFee .el-form-item__content {
  width: 206px;
  display: inline-block;
}
.materialsList .el-form-item__label {
  vertical-align: top;
}
</style>